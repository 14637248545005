import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import('./components/Login.vue'),
      meta: { auth: false }
    },
    {
      path: '/forgot',
      component: () => import('./components/Forgot.vue'),
      meta: { auth: false }
    },
    {
      path: '/search',
      component: () => import('./components/Search.vue'),
      meta: { auth: true }
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: { auth: false },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: { auth: false }
        },
        {
          name: 'Point Of Sale',
          path: 'pages/point-of-sale',
          component: () => import('@/views/dashboard/pages/Home'),
          meta: { auth: true }
        },
        {
          name: 'Users',
          path: 'pages/user-tables',
          component: () => import('@/views/dashboard/pages/Users'),
          meta: { auth: true }
        },
        {
          name: 'Categories',
          path: 'pages/category-tables',
          component: () => import('@/views/dashboard/pages/Categories'),
          meta: { auth: true }
        },
        {
          name: 'Purchase Order',
          path: 'pages/purchase-order-tables',
          component: () => import('@/views/dashboard/pages/PurchaseOrder'),
          meta: { auth: true }
        },
        {
          name: 'Products',
          path: 'pages/product-tables',
          component: () => import('@/views/dashboard/pages/Products'),
          meta: { auth: true }
        },
        {
          name: 'Orders',
          path: 'pages/order-tables',
          component: () => import('@/views/dashboard/pages/Orders'),
          meta: { auth: true }
        },
        {
          name: 'report-order',
          path: 'pages/report-order/:id',
          component: () => import('@/views/dashboard/pages/ReportOrder'),
          meta: { auth: true }
        },
        {
          name: 'Promotion',
          path: 'pages/promotion-tables',
          component: () => import('@/views/dashboard/pages/Promotion'),
          meta: { auth: true }
        },
        {
          name: 'Cash Flow',
          path: 'pages/chas-flow-tables',
          component: () => import('@/views/dashboard/pages/Cashflow'),
          meta: { auth: true }
        },
        {
          name: 'Media Information',
          path: 'pages/information-tables',
          component: () => import('@/views/dashboard/pages/Information'),
          meta: { auth: true }
        },
        {
          name: 'Courier',
          path: 'pages/courier-tables',
          component: () => import('@/views/dashboard/pages/Courier'),
          meta: { auth: true }
        },
        {
          name: 'Code Reveral Iot',
          path: 'pages/code-reveral-iot-tables',
          component: () => import('@/views/dashboard/pages/CodeReveralIot'),
          meta: { auth: true }
        },
        {
          name: 'Licence',
          path: 'pages/licence-tables',
          component: () => import('@/views/dashboard/pages/Licence'),
          meta: { auth: true }
        },
        {
          name: 'Store Setting',
          path: 'pages/store-setting-tables',
          component: () => import('@/views/dashboard/pages/StoreSetting'),
          meta: { auth: true }
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
          meta: { auth: true }
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/ErrorPage'),
    },

  ],
})

router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if (store.getters['auth/guest']) {
      // tampilkan pesan bahwa harus login dulu
      store.dispatch('alert/set', {
        status: false,
        text: 'Login first',
        color: 'error',
      })
      store.dispatch('setPrevUrl', to.path)
      // tampilkan form login
      store.dispatch('dialog/setComponent', 'login')
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})
export default router;