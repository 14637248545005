export default {
    namespaced: true,
    state: {
        flatform    : {},
    },
    mutations: {
        set: (state, payload) => {
            state.flatform  = payload
        },
    },
    actions: {
        set: ({commit}, payload) => {
            commit('set', payload)
        },
    },
    getters: {
        flatform  : state => state.flatform,
    }
}