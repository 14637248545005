export default {
  namespaced: true,
  state: {
      provinces: [],
      cities: [],
      subdistrict: [],
  },
  mutations: {
      setProvinces: (state, value) => {
          state.provinces = value
      },
      setCities: (state, value) => {
          state.cities = value
      },
      setSubdistrict: (state, value) => {
        state.subdistrict = value
    },
  },
  actions: {
      setProvinces: ({commit}, value) => {
          commit('setProvinces', value)
      },
      setCities: ({commit}, value) => {
          commit('setCities', value)
      },
      setSubdistrict: ({commit}, value) => {
        commit('setSubdistrict', value)
    },
  },
  getters: {
      provinces: state => state.provinces,
      cities: state => state.cities,
      subdistrict: state => state.subdistrict,
  }
} 